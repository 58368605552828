import React from 'react';
import '../styles/TheProposal.css';
import arrow from '../img/components/arrow.png';
import { Fade } from 'react-reveal';

const TheProposal = () => {
  return (
    <div className="pro-our-story">
        <div className='ourstorycontent'>
    <Fade left  duration={2000}>
      <h2>- The Proposal -</h2>
    </Fade>  
    <Fade bottom duration={3000}>
      <p>- Grooms pov</p>
      <p>
     During the last days of 2021, I decided to purchase a bracelet to propose because the ring thing was a bit cliché. I had it planned out time, place and date-  1st January 2022.
     On 31st December 2021, I had everything ready for the proposal the next day. I had called Efua earlier that day to tell her that I was going to take her out to start off the year. 
     After church that 31st Night of December, I realized I could not find my car key. I looked everywhere, it was tensious!  Things got worse when I broke my car remote in an attempt to find it, exposing the inner contents which was the start of my issues. 
     After several minutes of searching, I finally found the key, but the car won’t start! That’s how I got stuck with my car outside my church from 1am trying everything to get the car. 
     Boys gathered, everyone trying to figure out how to start the car! Finally at 3pm, someone asked for the parts of the remote. He then figured a very small part of the remote, the transponder was what I needed to start the car. 
     After an excruciating 10 hours, I finally moved my car and headed home to take a quick shower and change then moved straight to pick her up for the date. 
     The date couldn’t have gone any worse lol. We couldn’t get good private seat in the restaurant and the food was below average despite the promise of the restaurant being on the best in town. 
     Finally the moment came, I broke a sweat. Ei! I knew she will say yes but Charle. I took her hand smiled at her and said “You know I love you right” 
     She gave me one of those her “ Ei brada nie” looks. I chuckled and gave my speech. Can’t remember the content word for word but I know it ended with “Will you marry me?” This girl started laughing. Right before my heart skipped a beat she said “Yes”. 
     Until I wrote this, I didn’t realize how truly dramatic that day was😂-
      </p>
      </Fade>
      <img src={arrow} alt="arrow" className="arrow" />
      
      </div>
    </div>
  );
};

export default TheProposal;
