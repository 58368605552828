import React from 'react';
import '../styles/OurStory.css';
import arrow from '../img/components/arrow.png';
import { Fade } from 'react-reveal';

const OurStory = () => {
  return (
    <div className="our-story" id='our-story'>
        <div className='ourstorycontent'>
    <Fade left  duration={2000}>
      <h2>- Our Story -</h2>
    </Fade>  
    <Fade bottom duration={3000}>
      <p>
     - On a mutual friend’s birthday was when we first interacted.Not so many words exchanged but that was how we first struck an acquaintance. Many hello(s) and hi(s) exchanged casually on campus turned into something else when she joined the Levites Generation (L.G) Poetry department where I was already a member.
    When I saw her then I knew exactly where to look and that was the start of the pursuit. A pizza date and a question popped and we came back to campus after vacation having started this beautiful journey. It’s been years of intentional love and growing with each other. I’m so glad we’re doing forever together.-
      </p>
      </Fade>
      <img src={arrow} alt="arrow" className="arrow" />
      
      </div>
    </div>
  );
};

export default OurStory;
