import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Dashboard.css';

const Dashboard = () => {
  const [names, setNames] = useState([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get('https://relieved-capris-mite.cyclic.app/dashboard');
        setNames(response.data);
      } catch (error) {
        console.error('Error during dashboard retrieval:', error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div className="dashboard-container">
      <h2 className="dashboard-title">rsvp list</h2>
      <hr />
      <p>{names.length} Guests</p>
      
      {names.length === 0 ? (
        <p className="no-names-message">No names listed yet</p>
      ) : (
        <ul className="names-list">
          {names.map((name, index) => (
            <li key={name} className="names-list-item">
             <strong>{index +1}. </strong> {name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dashboard;
