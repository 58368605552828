// HomeSection.js
import React from 'react';
import '../styles/HomeSection.css';
import img1 from '../img/one1.jpg';
import img2 from '../img/two.jpg';
import img3 from '../img/three.jpg';
import img4 from '../img/components/img4.png';
import img5 from '../img/five.jpg';
import img6 from '../img/six.jpg';
import Header from '../components/Header';

const HomeSection = () => {
  // Replace these with your actual image URLs
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
  ];

  return (
    <section className="home-section"  id='home'>
      <div className="left-section">
      <p className='top' >Save the date for</p>
        <h1>#BecomingtheNeds</h1>
        <p className='bottm'>April 6, 2024</p>
      </div>
      <div className="right-section">
        {/* Include the grid of images directly */}
        <div className="container">
          {images.map((image, index) => (
            <div key={index} className="image-grid-item">
              <img src={image} alt={`Ie ${index + 1}`} loading='lazy' />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomeSection;
