import React, { useState } from 'react';
import axios from 'axios';
import '../styles/RSVP.css';

const RSVP = () => {
  const [username, setUsername] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [rsvpReply, setRSVPReply] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorReply, setErrorReplyMessage] = useState('');

  const handleRSVP = async () => {
    try {
      if (!username) {
        setErrorMessage(true);
        setErrorReplyMessage('Please enter your name');
        setTimeout(() => {
          setErrorMessage(false);
        }, 4000);
        return;
      }

      await axios.post('https://relieved-capris-mite.cyclic.app/rsvp', {
        name: username,
      });
      setUsername('');
      setShowMessage(true);
      setRSVPReply(`Thanks for RSVP-ing ${username}! See you soon!`);
      setTimeout(() => {
        setShowMessage(false);
      }, 4000);
    } catch (error) {
      console.error('Error during RSVP:', error);
      setErrorMessage(true);
      setErrorReplyMessage('An error occurred. Please try again later.');
      setTimeout(() => {
        setErrorMessage(false);
      }, 4000);
    }
  };

  return (
    <div className="rsvp-form-container" id='rsvp'>
      <h3>R.S.V.P</h3>
      <input
        type="text"
        placeholder="Enter your Full name.."
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="rounded-input"
      />
      <button onClick={handleRSVP}>RSVP</button>

      {showMessage && (
        <div id="rsvpReply" className="rsvp-reply">
          {rsvpReply}
        </div>
      )}

      {errorMessage && (
        <div id="rsvpReply" className="rsvp-reply">
          {errorReply}
        </div>
      )}
    </div>
  );
};

export default RSVP;
