import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/Messages.css'; // Import your component-specific CSS file

function Messages() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000, // Adjust speed to control the sliding speed
    autoplay: true, // Enable autoplay
    autoplaySpeed: 6000, // Adjust autoplay speed (in milliseconds)
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className='messages-container' id='messages'> 
      <div className='messgs'>
      <h2>Messages from the Groom and bridal party</h2>
      <Slider {...settings}>
        <div className='sldd'>
          <p>Love is a beautiful thing and when you find that one special person you want to share it with, you hold on tightly. Ned and Efua's love has really stood the test of time and I'm very happy to see them start this new chapter in their lives. I love them both and I pray God gives them the grace in the journey ahead of them. Cheers to rare love 🥂</p>
          <p> ~ Stephanie </p>
        </div>
        <div className='sldd'>
          <p>My selfless friend Nyamekye, scripture says, in his time he makes all things beautiful! I’m excited that this truth is evident in both your lives. May God add to your beauty, joy, favor, fruitfulness, grace, wealth, strength and glory. Congratulations to the Neds!</p>
          <p> ~ Dagline </p>
        </div>
        <div className='sldd'>
          <p>Have a blast in this thing called marraige ma G's.</p>
          <p> ~ Stephen </p>
        </div>
        <div className='sldd'>
          <p>Join us in celebrating the union of two souls destined for a lifetime of love and laughter! Here's to many more sweet moments and shared adventures ahead. Cheers to love, laughter, and happily ever after! #BecomingtheNEDs🎊</p>
          <p> ~ Laud </p>
        </div>
        <div className='sldd'>
          <p>Congratulations on your wedding day! Wishing you both a lifetime filled with love, laughter, and endless happiness together. May this new chapter in your lives be filled with beautiful memories and shared dreams. Cheers to the love that brought you together and the journey that lies ahead.</p>
          <p> ~ Annette </p>
        </div>
        <div className='sldd'>
          <p>Hey NED! I want to send some love your way. Kobbyjollof say…
        K - Keep shining as the life of the party. 
        O - Overjoyed to celebrate your special day. 
        B - Best wishes for a lifetime of happiness 
              and love. 
        B - Beaming with excitement for you both. 
        Y - You deserve all the happiness in the 
              world! 
        J - Just know we've got your back, always. O - Our friendship will only grow stronger. 
        L - Love and laughter will fill your days. 
        L - Let the adventure of marriage begin! 
        O - On this journey, you'll never be alone. 
        F - Forever grateful to have you as a friend. 
              Congrats, Guy! 🎉💍</p>
          <p> ~ Solomon </p>
        </div>
        <div className='sldd'>
          <p>What I admire most about these two is their determination as a couple and not letting “the outside world” tell them how they should feel about each other. Nana Eduful and Nana Efua, I wish you both nothing but success and happiness in your marriage chale. 🥂(This doesn’t change how I feel about lawyers btw).</p>
          <p> ~ Katherine </p>
        </div>
        <div className='sldd'>
          <p>Wishing you both a lifetime of love, laughter, and cherished moments together. Your love story is an inspiration to us all! Congratulations on this beautiful journey ahead.</p>
          <p> ~ Prince </p>
        </div>
        <div className='sldd'>
          <p>May your union be a journey of love, understanding, and endless joy. Wishing Ned and Efua a lifetime filled with shared dreams, cherished moments, and a love that grows stronger with each passing day. Congratulations on your new beginnings together!.#BecomingtheNEDs</p>
          <p> ~ Kwamena </p>
        </div>
        <div className='sldd'>
          <p>Muniru and Esquire, when I met you both in level 100 at central, I never imagined I’d be on the path to being life long friends with you two. At first glance, a stranger would probably think you’re way too different from each other, but you two were probably cut from the same cloth. It has been a thrill witnessing your growth all these years till this point. May your journey together continue to be filled with joy and happiness and love and everything sweet and nice.</p>
          <p> ~ Abraham </p>
        </div>
        <div className='sldd'>
          <p>My selfless friend Nyamekye, scripture says, in his time he makes all things beautiful! I’m excited that this truth is evident in both your lives. May God add to your beauty, joy, favor, fruitfulness, grace, wealth, strength and glory. Congratulations to the Neds!❤️😍💃💃</p>
          <p> ~ Dagline </p>
        </div>
        <div className='sldd'>
          <p>You guys set the example in so many areas. I'm rooting for you guys hard and I pray for the best for you. I love that I know and have memories with both of you and I've witnessed your life and love grow and it's been up and up all the way. You're going to enjoy your selves, for sure. Me dier adey your back dey come Chale!!!</p>
          <p> ~ Stevie </p>
        </div>
        <div className='sldd'>
          <p>Shouts Out to my people, Ned and Efua.  MSGS:  To my day one Bro, it brings me joy to witness the fruition this day/time as was envisioned/discussed on an occasional drive to miotso, its being a fairly tough journey, with a great deal of solace and laughter courtesy Efua(Hajia- with stones),  So I say congratulations to you both, I’m so happy you found gems in each other. May your years ahead be full of happiness, peace and wealth and everything you’ve dreamed of and more</p>
          <p> ~ Phil </p>
        </div>
        <div className='sldd'>
          <p>Me nua, you and your wife to-be are amazing people and you really compliment each other. I love that you love her, she loves you and that you both love God. I am going to be your number one fan, both of you. My heart goes out to you both and your new family.</p>
          <p> ~ Tedd </p>
        </div>
      </Slider>
      </div>
    </div>
  );
}

export default Messages;
