import React from 'react';
import '../styles/Venue.css';
import churchimage from '../img/actualmap.jpg';


function VenueDetails() {
  const handleImageClick = () => {
    // Redirect user to Google Maps link
    window.location.href = 'https://maps.app.goo.gl/FFPRF23mX2Rxa6CBA?g_st=iw';
  };

  return (
    <div className="venue-container" id='venue-details'>
       <h2>Directions</h2>
       <p>White Wedding</p>
      <div className="venue-image" onClick={handleImageClick} style={{ backgroundImage: `url(${churchimage})` }}>
        <div className="overlay">
          <h3>Click for location <i class="fa fa-map-pin" aria-hidden="true"></i></h3>
        </div>
      </div>
    </div>
  );
}

export default VenueDetails;
