// CountdownTimer.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../styles/CountdownTimer.css';

const CountdownTimer = () => {
  const weddingDate = new Date('2024-04-06T00:00:00Z'); // Updated wedding date
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());
  const [showModal, setShowModal] = useState(false);
  const [showTimer, setShowTimer] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = getTimeRemaining();
      setTimeRemaining(remaining);

      if (remaining.total <= 0) {
        clearInterval(interval);
        setShowModal(true);
        setShowTimer(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function getTimeRemaining() {
    const now = new Date();
    const totalMilliseconds = weddingDate - now;
    const totalSeconds = Math.floor(totalMilliseconds / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return {
      total: totalMilliseconds,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  function formatTime(value) {
    return value < 10 ? `0${value}` : value;
  }

  return (
    <div className="countdown-timer" id='countdown-timer'>

      {showTimer && (
        <div className="timer">
          <p>Counting down to the big day!!</p>
          {/* Display countdown timer */}
          <span className='daysbox'>{formatTime(timeRemaining.days)}:</span>
          <span className='hoursbox'>{formatTime(timeRemaining.hours)}:</span>
          <span className='minutesbox'>{formatTime(timeRemaining.minutes)}:</span>
          <span className='secondsbox'>{formatTime(timeRemaining.seconds)}</span>
        </div>
      )}
      <Modal
        isOpen={showModal}
        contentLabel="Wedding Day Modal"
        onRequestClose={() => setShowModal(false)}
        className="Modal"
        overlayClassName="Overlay"
      >
        <p>It's Our Wedding Day!!</p>
      </Modal>
    </div>
  );
};

export default CountdownTimer;
