import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';
import CountdownTimer from './CountdownTimer';
import RSVP from './RSVP';
import Header from './Header';
import HomeSection from './HomeSection';
import OurStory from './OurStory';
import Imagesection from './Imgsection';
import TheProposal from './TheProposal';
import Messages from './Messages';
import Venue from './Venue';
import Footer from './Footer';


function Main() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    setTimeout(() => {
      setLoading(false);
    }, 3000);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if (loading) {
    return (
      <div className='spinnergffff'>
      <div class="loading">
        <svg height="48px" width="64px">
            <polyline id="back" points="0.157 23.954, 14 23.954, 21.843 48, 43 0, 50 24, 64 24"></polyline>
          <polyline id="front" points="0.157 23.954, 14 23.954, 21.843 48, 43 0, 50 24, 64 24"></polyline>
        </svg>
      </div>
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      {windowWidth <= 768 ? <Header /> : null}
      {windowWidth > 768 ? <HomeSection /> : null}
      <OurStory />
      <TheProposal />
      <Imagesection />
      <Messages />
      <CountdownTimer />
      <Venue />
      <RSVP />
      <Footer />
    </div>
  );
}

export default Main;
