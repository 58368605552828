import React from 'react';
import '../styles/Footer.css';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="footer">
      <p className="copywrite">© {currentYear} #BecomingtheNeds</p>
      <p className="copywriter">Created by <a href="https://we-devsgh.online/" target="_blank" rel="noopener noreferrer">wedevsgh</a></p>

    </footer>
  );
}

export default Footer;
