import React, { useState } from 'react';
import Logo from '../img/nxelogo.png';
import '../styles/Navbar.css';

const NavBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToSections = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
    toggle();
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img src={Logo} alt="Wedevs Logo" />
        </div>
        <div className="navbar-links">
          <a href="#home" onClick={() => scrollToSection('home')}>Home</a>
          <a href="#our-story" onClick={() => scrollToSection('our-story')}>Couple Story</a>
          <a href="#countdown-timer" onClick={() => scrollToSection('countdown-timer')}>Countdown</a>
          <a href="#photos" onClick={() => scrollToSection('photos')}>Photos</a>
          <a href="#venue-details" onClick={() => scrollToSection('venue-details')}>Venue Details</a>
          <a href="#messages" onClick={() => scrollToSection('messages')}>Messages</a>
        </div>
        <div className="navbar-rsvps">
          <button onClick={() => scrollToSection('rsvp')} >RSVP</button>
        </div>
        <div className="nav-it" onClick={toggle}>
        <div className="navbar-hamburger" onClick={toggle}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="linedtext">
            <p>MENU</p>
          </div>
        </div>


        {isModalOpen && (
          <div className="modal-overlay" onClick={toggle}>
            <div className="modals" onClick={(e) => e.stopPropagation()}>
              <div className="navbar-logo">
                <img src={Logo} alt="Wedevs Logo" />
              </div>
              <a className="sidelinks" href="#home" onClick={() => scrollToSections('homes')}>Home</a>
              <a className="sidelinks" href="#our-story" onClick={() => scrollToSections('our-story')}>Couple Story</a>
              <a className="sidelinks" href="#countdown-timer" onClick={() => scrollToSections('countdown-timer')}>Countdown</a>
              <a className="sidelinks" href="#photos" onClick={() => scrollToSections('photos')}>Photos</a>
              <a className="sidelinks" href="#venue-details" onClick={() => scrollToSections('venue-details')}>Venue Details</a>
              <a className="sidelinks" href="#messages" onClick={() => scrollToSections('messages')}>Messages</a>
              <button onClick={() => scrollToSection('rsvp')} >RSVP</button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
