import React from 'react'
import '../components/Header.css';
import { Fade } from 'react-reveal';


const Header = () => {


  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });

  };

  
  return (
    <header
      id='homes'
      className='header valign bg-img parallaxie'
    >

          <Fade left  duration={3000}>
            <h1 className='animate-box' data-animate-effect='fadeInUp'>
              #BecomingtheNeds
            </h1>
            </ Fade>
            <Fade right duration={3000}>
            <h5 className='animate-box' data-animate-effect='fadeInUp'>
              _____  06 . 04 . 2024   _____
            </h5>
            </ Fade>

          <Fade bottom duration={5000}>
          <div className="navbars-rsvp">
          <button onClick={() => scrollToSection('rsvp')}>RSVP</button>
        </div>
        </Fade>
    </header>
  )
}

export default Header
