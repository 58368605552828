import React, { useRef, useEffect } from 'react';
import '../styles/Imagesection.css';
import '../styles/HomeSection.css';
import { Fade } from 'react-reveal';
import img1 from '../img/neCover.jpg';
import img2 from '../img/components/img2.jpg';
import img3 from '../img/components/img3.jpg';
import img4 from '../img/efuaalone.jpg';
import img5 from '../img/components/img5.jpg';
import img6 from '../img/components/img6.jpg';
import img7 from '../img/nedalone.jpg';
import img8 from '../img/clear1.jpg';

const Imgsection = () => {
  const imgSectionRef = useRef(null);

  useEffect(() => {
    const parallaxScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const parallaxElements = imgSectionRef.current.querySelectorAll('.parallax');
      
      parallaxElements.forEach(element => {
        const parallaxSpeed = parseFloat(element.getAttribute('data-parallax-speed'));
        element.style.transform = `translateY(${scrollTop * parallaxSpeed}px)`;
      });
    };

    window.addEventListener('scroll', parallaxScroll);
    return () => {
      window.removeEventListener('scroll', parallaxScroll);
    };
  }, []);

  return (
    <div className="image-section" id='photos' ref={imgSectionRef}>
      <Fade left duration={2000}>
        <h2>Some of our favourite shots</h2>
      </Fade>

      <div className="parallax-image-wrapper">
        <div className="imgleftsection parallax" data-parallax-speed="0.1">
        <Fade left duration={2000}>
          <img src={img1} alt="" className='leftpair1' loading='lazy' />
        </Fade>
          <img src={img2} alt="" className='leftpair2' loading='lazy' />
          <Fade bottom duration={1000}>
          <img src={img3} alt="" className='leftpair3' loading='lazy' />
          </Fade>
          <img src={img4} alt="" className='leftpair4' loading='lazy' />
        </div>

        <div className="imgrightsection parallax" data-parallax-speed="0.25">
        <Fade right duration={2000}>
          <img src={img5} alt="" className='rightpair1' loading='lazy' />
        </Fade>
        <Fade right duration={2500}>
          <img src={img6} alt="" className='rightpair2' loading='lazy' />
        </Fade>
        <Fade left duration={2000}>
          <img src={img7} alt=""  className='rightpair3' loading='lazy' />
          <img src={img8} alt="" className='rightpair4' loading='lazy' />
          </Fade>
        </div>
      </div>
      <div style={{ height: '100px' }}></div>
    </div>
  );
};

export default Imgsection;
